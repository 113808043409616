<div class="col" *ngIf="lang == 'en-us'">
  <div class="row r-rules-row">
    <div class="col-md-12">
      <div class="heading4-Rules-main">
        <h4 class="heading4-Rules">{{ "PARKING_POLICY.PARKING_POLICY_RULES_TEXT" | translate }}</h4>
      </div>

      <div class="Rules-Policy-Details">
        <div class="below-are">
          <p>
            {{ "PARKING_POLICY.PARKING_POLICY_RULES_INSTRUCTIONS" | translate }}
          </p>
        </div>
        <iframe [src]="policyUrl" width="100%" height="100%" frameborder="0" style="overflow:scroll; overflow-x: hidden;"></iframe>


<!--        <div *ngFor="let parking_rules of parking_policy_rules; let i= index">-->
<!--        <div [ngClass]="setParkingRulesBoxColorStyle(parking_rules.color_id)">-->
<!--          <h4 class="heading40-Rules">{{parking_rules.policy_name}}</h4>-->
<!--          <h4 class="heading40-Rules">{{parking_rules.policy}}</h4>-->
<!--        </div>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</div>

<div class="col" *ngIf="lang == 'es-mx'">
  <div class="row r-rules-row">
    <div class="col-md-12">
      <div class="heading4-Rules-main">
        <h4 class="heading4-Rules">{{ "PARKING_POLICY.PARKING_POLICY_RULES_TEXT" | translate }}</h4>
      </div>

      <div class="Rules-Policy-Details">
        <div class="below-are">
          <p>
            {{ "PARKING_POLICY.PARKING_POLICY_RULES_INSTRUCTIONS" | translate }}
          </p>
        </div>
        <iframe [src]="policyUrl" width="100%" height="100%" frameborder="0" style="overflow:scroll; overflow-x: hidden;"></iframe>
<!--        <div *ngFor="let parking_rules of parking_policy_rules; let i= index">-->
<!--          <div [ngClass]="setParkingRulesBoxColorStyle(parking_rules.color_id)">-->
<!--            <h4 class="heading40-Rules">{{parking_rules.policy_name_es}}</h4>-->
<!--            <h4 class="heading40-Rules">{{parking_rules.policy_es}}</h4>-->
<!--          </div>-->
<!--          </div>-->
      </div>
    </div>
  </div>
</div>

<div class="col" *ngIf="lang == 'pt-pt'">
  <div class="row r-rules-row">
    <div class="col-md-12">
      <div class="heading4-Rules-main">
        <h4 class="heading4-Rules">{{ "PARKING_POLICY.PARKING_POLICY_RULES_TEXT" | translate }}</h4>
      </div>

      <div class="Rules-Policy-Details">
        <div class="below-are">
          <p>
            {{ "PARKING_POLICY.PARKING_POLICY_RULES_INSTRUCTIONS" | translate }}
          </p>
        </div>
        <iframe [src]="policyUrl" width="100%" height="100%" frameborder="0" style="overflow:scroll; overflow-x: hidden;"></iframe>
<!--        <div *ngFor="let parking_rules of parking_policy_rules; let i= index">-->
<!--          <div [ngClass]="setParkingRulesBoxColorStyle(parking_rules.color_id)">-->
<!--            <h4 class="heading40-Rules">{{parking_rules.policy_name_pt}}</h4>-->
<!--            <h4 class="heading40-Rules">{{parking_rules.policy_pt}}</h4>-->
<!--          </div>-->
<!--          </div>-->
      </div>
    </div>
  </div>
</div>

<div class="col" *ngIf="lang == 'ht-ht'">
  <div class="row r-rules-row">
    <div class="col-md-12">
      <div class="heading4-Rules-main">
        <h4 class="heading4-Rules">{{ "PARKING_POLICY.PARKING_POLICY_RULES_TEXT" | translate }}</h4>
      </div>

      <div class="Rules-Policy-Details">
        <div class="below-are">
          <p>
            {{ "PARKING_POLICY.PARKING_POLICY_RULES_INSTRUCTIONS" | translate }}
          </p>
        </div>
        <iframe [src]="policyUrl" width="100%" height="100%" frameborder="0" style="overflow:scroll; overflow-x: hidden;"></iframe>
<!--        <div *ngFor="let parking_rules of parking_policy_rules; let i= index">-->
<!--          <div [ngClass]="setParkingRulesBoxColorStyle(parking_rules.color_id)">-->
<!--            <h4 class="heading40-Rules">{{parking_rules.policy_name_ht}}</h4>-->
<!--            <h4 class="heading40-Rules">{{parking_rules.policy_ht}}</h4>-->
<!--          </div>-->
<!--          </div>-->
      </div>
    </div>
  </div>
</div>
