import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { ParkingPolicyMainComponent } from 'src/app/parking-policy-main/parking-policy-main.component';
import { PropertyKeywordService } from 'src/app/services/sign-up/property-keyword.service';

@Component({
  selector: 'app-parking-policy',
  templateUrl: './parking-policy.component.html',
  styleUrls: ['./parking-policy.component.css']
})
export class ParkingPolicyComponent implements OnInit {

  @ViewChild('parkingpassSignUpIssues') parkingpassSignUpIssues
  @ViewChild('signAndFinishButton') signAndFinishButton

  @ViewChild('ParkingPolicyMainComponent') ParkingPolicyMainComponent: ParkingPolicyMainComponent

  public parkingPolicyForm: FormGroup
  public residentForm: FormGroup = null
  public property_uuid: string

  public submittingForm: boolean = false
  public submitted: boolean = false

  public loading: boolean = false

  public systemDomain: string

  @Output() parkingPolicySigned = new EventEmitter()
  @Output('parkingPolicyLoading') parkingPolicyLoading = new EventEmitter()

  constructor(private formBuilder: FormBuilder , private propertyKeywordService: PropertyKeywordService) { }

  public termsError<T>(operation = 'operation', result?: T) {

    this.submittingForm = false

    return (error: any): Observable<T> => {

      console.log(error)

      let signUpInstructions = this.parkingpassSignUpIssues.nativeElement

      signUpInstructions.className = 'signUpBoxInstructions parkingpass-error-red'
      signUpInstructions.style.display = 'none'

      signUpInstructions.innerHTML = "There was an error signing-up."

      const error_list = error.error.errors

      if(error_list.password_confirmation){

        let errors: {[k: string]: any} = {};
        error_list.password_confirmation.forEach(error => {
          error[error] = true
        })          
        this.parkingPolicyForm.get('parkingpassConfirm').setErrors(errors)
        
      }

      this.submittingForm = false

      setTimeout(function() {
        signUpInstructions.style.display = 'block' 
      } , 200)  

      // Let the app keep running by returning an empty result.
      return of(result as T)

    }

  }

  get f() { return this.parkingPolicyForm.controls }

  public initForm(): void{

    this.parkingPolicyForm = this.formBuilder.group({
      acceptParkingTerms: [false, Validators.requiredTrue]
    });

  }

  public signTerms(): void{
    this.submitted = true;
    this.parkingPolicySigned.emit(null)
  }

  public switchSystem(systemDomain: string){
    this.systemDomain = systemDomain
    this.ParkingPolicyMainComponent.systemDomain = systemDomain
  }

  public getParkingPolicyData(property_keyword: string , system: string) {

    this.parkingPolicyLoading.emit(true)
    let data = {
      property_keyword : property_keyword,
      system : system
    }
    this.propertyKeywordService.getParkingPolicyDetails(data).subscribe(
      resp => {
        if(resp.success === true) {
          this.parkingPolicyLoading.emit(false)
          this.ParkingPolicyMainComponent.initParkingPolicyDetails(resp);
        }
      }
    )
  }

  ngOnInit() {
    this.initForm()
  }

}
