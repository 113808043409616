import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-parking-policy-main',
  templateUrl: './parking-policy-main.' +
      'component.html',
  styleUrls: ['./parking-policy-main.component.css']
})
export class ParkingPolicyMainComponent implements OnInit, OnChanges {

  @Input() property_uuid: string;
  
  public lang: string;
  public systemDomain: string;
  public system;
  public parking_policy_rules: Array<any> = [];
  public policyUrl: SafeResourceUrl;
  public property_name : string
  public guest_vehicle_allowed : string
  public resident_vehicle_allowed : string
  public guest_pass_reset : string
  public guest_pass_limit : string
  public guest_consecutive_day_limit : string
  public patrolling_start_time : string
  public patrolling_end_time : string
  public is_enable_display_patrol_hours : boolean

  constructor(
    private translateService: TranslateService,
    private sanitizer: DomSanitizer
  ) { }

  public initParkingPolicyDetails(httpReponse : any) {
    if(httpReponse.success === true) {
      //reset initial value 
      this.parking_policy_rules = []
      //for parking policy rules

      if( httpReponse.data.parking_policy_rules ) {
        httpReponse.data.parking_policy_rules.forEach(parking_policy_rules => {
          this.parking_policy_rules.push(parking_policy_rules)
        });
      }
      
      //sort by color priority
      const colorPriority = {1: 1, 3: 2, 2: 3}; 

      this.parking_policy_rules.sort((a, b) => {
        return colorPriority[a.color_id] - colorPriority[b.color_id];
      });
      //for parking policy details
      this.property_name = httpReponse.data.parking_policy_details.property_name,
      this.guest_vehicle_allowed = httpReponse.data.parking_policy_details.guest_vehicle_max,
      this.resident_vehicle_allowed = httpReponse.data.parking_policy_details.resident_vehicle_max,
      this.guest_pass_reset = httpReponse.data.parking_policy_details.guest_vehicle_reset,
      this.guest_pass_limit = httpReponse.data.parking_policy_details.guest_vehicle_limit,
      this.guest_consecutive_day_limit = httpReponse.data.parking_policy_details.guest_vehicle_period,
      this.patrolling_start_time = httpReponse.data.patrolling_start_time,
      this.patrolling_end_time = httpReponse.data.patrolling_end_time,
      this.is_enable_display_patrol_hours = httpReponse.data.is_enable_display_patrol_hours
    }
  }

  public setParkingRulesBoxColorStyle(color_id : any) {
    if(color_id == 1)
      return 'Rules-col-red'
    else if(color_id == 2)
      return 'Rules-col-blue'
    else if(color_id == 3)
      return 'Rules-col-yellow'
  }

  ngOnInit() {
    this.lang = this.translateService.currentLang
    this.translateService.onLangChange.subscribe(
      resp => {
        this.lang = resp.lang 
      }
    )
   this.updatePolicyUrl();
  }
  
  private updatePolicyUrl() {
    if (this.property_uuid) {
      const url = 'https://my.parkingpass.com/patrol/parking-rules/view/' + this.property_uuid;
      this.policyUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }
  
  ngOnChanges(changes: SimpleChanges) {
    if (changes.property_uuid) {
      this.updatePolicyUrl();
    }
  }

}
